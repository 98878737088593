<template>
  <div>
    <van-swipe>
      <van-swipe-item>
        <img style="width: 100%" src="@/assets/product/banner-1.jpg" />
      </van-swipe-item>
    </van-swipe>

    <div class="editor-card">
      <div style="display: flex">
        <span class="title" style="flex: 1">
          {{ $i18n.locale === 'enUS' ? info.nameEn : info.name }}
        </span>
        <span style="width: 80px; text-align: right">
          <a
            v-if="
              ($i18n.locale === 'enUS' && info.dataResourceUrlEn) ||
              ($i18n.locale !== 'enUS' && info.dataResourceUrl)
            "
            class="info-url"
            target="_blank"
            :href="
              $i18n.locale === 'enUS'
                ? info.dataResourceUrlEn
                : info.dataResourceUrl
            "
          >
            {{ $t('message')['product.data.dowload'] }}
          </a>
        </span>
      </div>

      <div
        class="editor-content"
        v-html="$i18n.locale === 'enUS' ? info.richTextEn : info.richText"
      />
    </div>
  </div>
</template>

<script>
import { getLanguage } from '@/utils/languageUtils'
import { getProductDetail } from '@/api/product'
export default {
  name: 'ProductDetail',
  data() {
    return {
      id: '',
      info: {},
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getProductInfo()
  },
  methods: {
    getProductInfo() {
      const toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...',
      })
      getProductDetail(this.id)
        .then((res) => {
          if (res.code === 200) {
            this.info = res.data
          } else if (res.code === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$toast.fail(res.message)
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$toast.fail(err.message)
          }
        })
        .finally(() => {
          toast.clear()
        })
    },
  },
}
</script>

<style lang="less" scoped>
.editor-card {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 20px;
}

.title {
  font-size: 1.4em;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.editor-content {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
}

.editor-content {
  /deep/ img {
    max-width: calc(100% - 40px) !important;
  }
}

.editor-content {
  /deep/ * {
    color: #333333;
    line-height: 30px;
    text-indent: 2em;
    font-size: 18px;
  }
}

.info-url {
  line-height: 1.5em;
  text-indent: 2em;
  font-size: 1.2em;
}
</style>
