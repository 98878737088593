import { render, staticRenderFns } from "./DialogDetail.vue?vue&type=template&id=7a32149a&scoped=true&"
import script from "./DialogDetail.vue?vue&type=script&lang=js&"
export * from "./DialogDetail.vue?vue&type=script&lang=js&"
import style0 from "./DialogDetail.vue?vue&type=style&index=0&id=7a32149a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a32149a",
  null
  
)

export default component.exports